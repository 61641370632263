.bill-table {
    /* border-collapse: collapse; */
    width: 100%;
    margin-top: 0;
    table-layout: fixed;
  }

  .bill-tabletwo {
    border-collapse: collapse;
    width: 65%;
    margin-top: -12.5px;
    height: "130px";
    border: 1px solid ;
    
  }
  
  .bill-table td {
    border: 1px solid ;
   
    text-align: center;
  }
  
  .bill-table td:nth-child(odd) {
    font-weight: bold;
  }
  
  .bill-table td[colspan] {
    text-align: left;
    border: '1px solid ';
  }

  .bill-table th {
    background-color: transparent;
    color: black;
    border: 1px solid;
  }
  
  