body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
    background-color: #f4f4f4;
  }


  #bill-containermaint {
    background-image: url('./mbg.jpg'); 
    background-repeat: no-repeat;
    background-position: 50% 60%;
    background-size: 500px 300px; 
    border: 1px solid #ccc;
    padding: 20px;
    width: 90%; 
    min-width: 70%; 
    max-width: 70%; 
    height: 90%; 
    margin: 0 auto;
    background-color: #fff;
    overflow: auto;
  }
  

  @media screen and (max-width: 70%) {
    #bill-containermaint {
      width: 70%; 
      max-width: none;
    }
  }

  #bill-container {
    background-image: url('./stamp\ e.jpg'); 
    background-repeat: no-repeat;
    background-position: 50% 60%;
    background-size: 500px 300px; 
    border: 1px solid #ccc;
    padding: 20px;
    width: 90%; 
    min-width: 70%; 
    max-width: 70%; 
    height: 90%; 
    margin: 0 auto;
    background-color: #fff;
    overflow: auto;
    margin-top: 100px;
  }
  

  @media screen and (max-width: 70%) {
    #bill-container {
      width: 70%; 
      max-width: none;
    }
  }

h1 {
text-align: center;
margin-bottom: 20px;
color: #333;
}

.section {
margin-bottom: 20px;
}

.section-title {
font-weight: bold;
margin-bottom: 10px;
color: #333;
}

table {
width: 100%;
border-collapse: collapse;
margin-bottom: 10px;
}

th{
padding:1px;
text-align: left;
border-bottom: 1px solid #ccc;
font-size: 12px;
height: 10px;
}

td{
  padding: 1px;
text-align: left;
border-bottom: 1px solid #ccc;
font-size: 10px;
font-family: Verdana, Geneva, Tahoma, sans-serif;
color: #333;
} 

th {
background-color: #000;
color: #fff;
}

.row {
display: flex;
justify-content: space-between;
margin-bottom: 10px;
}

.row label {
font-weight: bold;
color: #333;
}

.row-value {
margin-left: 10px;
color: #666;
}

.chart-container {
width: 100%;
margin-bottom: 20px;
}

.chart {
width: 100%;
height: 200px;
background-color: #f4f4f4;
border: 1px solid #ccc;
}

.barcode {
margin: 20px 0;
text-align: center;
}

.barcode img {
width: 200px;
height: 100px;
}

.total-amount {
margin-top: 20px;
text-align: right;
border-top: 1px solid #ccc;
padding-top: 10px;
}

.total-amount h2 {
color: #333;
}

.bill-details {
display: flex;
justify-content: space-between;
margin-bottom: 20px;
}

.bill-details .box {
width: calc(33.33% - 10px);
background-color: #f2f2f2;
padding: 10px;
border: 1px solid #ccc;
text-align: center;
}

.bill-details .box-title {
font-weight: bold;
margin-bottom: 10px;
}
  
.bill-history {
display: flex;

margin-bottom: 20px;
}

.bill-history .history-column {
width: calc(50% - 10px);
background-color: #f2f2f2;
padding: 10px;
border: 1px solid #ccc;
}

.bill-history .history-column table {
width: 100%;
}



.bill-Account {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  }
  
  .bill-Account .Account-column {
  width: calc(70% - 10px);
  background-color: #f2f2f2;
  padding: 10px;
  border: 1px solid #ccc;
  }
  
  .bill-Account .Account-column table {
  width: 100%;
  }




  .row-container {
    display: flex;
    justify-content: space-between;
    
  }









  .bill-his-section {
    display: flex;
    margin-bottom: 20px;
    width: 100%;
    background-color: white;
    /* padding: 10px; */
    /* border: 1px solid black; */
  }
  
  /* .bill-his-column {
   
  }
   */
  .bill-his-column table {
    width: 100%;
  }
  
  .bill-Acc-section {
    margin-top: -39px;
    height: 300px;
    display: flex;
    margin-bottom: 20px;
    width: 95%;
    background-color: white;
    /* padding: 10px; */
     border: 1px solid black; 
  }
  
  /* .bill-Acc-column {
    width: 95%;
    background-color: white;
    padding: 10px;
    border: 1px solid black;
  } */
  
  .bill-Acc-column table {
    width: 100%;
  }


  #bill-container { width: 70%; }
  



  









  